/* Container */
.signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--black);
    border-radius: 5px;
  }
  
  /* Form Title */
  .signup-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Form Input Fields */
  .signup-form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 0.1rem solid var(--orange);
    border-radius: 4px;
  }
  
  /* Form Button */
  .signup-form-button {
    width: 100%;
    padding: 10px;
    background-color: var(--orange);
    color: var(--white);
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Form Button Hover */
  .signup-form-button:hover {
    background-color: var(--orange);
  }
  
  /* Error Message */
  .signup-error-message {
    color: red;
    margin-top: 10px;
  }
  
  /* Success Message */
  .signup-success-message {
    color: var(--orange);
    margin-top: 10px;
  }
  