.modal-title {
  margin: 0;
  font-weight: 400;
  color: var(--moesif-black);
}

.modal-body {
  margin-block: 2.4rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.ReactModal__Overlay {
  /* override library default */
  background-color: rgba(39, 47, 52, 0.55) !important;
}

.ReactModal__Content {
  min-width: 612px !important;
  max-width: 1200px !important;
  padding: 24px !important;
}
