@tailwind base;
@tailwind components;
@tailwind utilities;

body, label {
    font-family: 'Manrope', sans-serif;
}

.bgHero {
  background-image: url('./images/assets/bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}